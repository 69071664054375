import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { PbwHttpService } from './pbw-http.service';
import { PbwStorageService } from './pbw-storage.service';

/**
 * Impedisce l'accesso alle route della shell se non si è autenticati
 */
@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private storageService: PbwStorageService, private httpService: PbwHttpService, private router: Router) {}

  canActivate(): Observable<boolean> {
    if (!this.storageService.get(ConfigSession.token)) {
      this.router.navigate(['/auth/login']);
      return of(false);
    }

    return this.httpService.get('/auth').pipe(
      map((response) => {
        if (!response) {
          this.router.navigate(['/auth/login']);
          return false;
        }
        return true;
      })
    );
  }
}
