import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { PbwPopAlertComponent } from './components/pbw-popalert.component';
import { AuthGuardService } from './services/auth-guard.service';
import { PbwCoreService } from './services/pbw-core.service';
import { PbwHttpInterceptor } from './services/pbw-http-interceptor';
import { PbwHttpService } from './services/pbw-http.service';
import { PbwPopAlertService } from './services/pbw-popalert.service';
import { PbwStateService } from './services/pbw-state.service';
import { PbwStorageService } from './services/pbw-storage.service';
import { PermissionGuardService } from './services/permission-guard.service';
import { PwaService } from './services/pwa-service.service';

@NgModule({
  imports: [HttpClientModule, CommonModule],
  declarations: [PbwPopAlertComponent],
  exports: [PbwPopAlertComponent],
  providers: [
    PbwCoreService,
    PbwHttpService,
    PbwPopAlertService,
    PbwStorageService,
    PbwStateService,
    AuthGuardService,
    PermissionGuardService,
    PwaService,
    { provide: HTTP_INTERCEPTORS, useClass: PbwHttpInterceptor, multi: true },
  ],
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
